@import url("https://fonts.googleapis.com/css2?family=Fuzzy+Bubbles&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@300&family=Ubuntu:wght@300&display=swap");

html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Ubuntu", sans-serif;
  background: #f5f5f5;
  height: 100%;
}



.App-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  font-size: 0.8rem;
}
